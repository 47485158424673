import { CRC32 } from './crc32';
import { notNil } from './misc';

interface CamelToSpaceOpts {
    lower?: boolean;
    spaceAroundNumber?: boolean;
}

export const camelToSpace = (str: string, opts: CamelToSpaceOpts = { lower: false, spaceAroundNumber: false }): string => {
    let s: string = str.replace(/([A-Z][a-z]|(?<=[a-z])[A-Z])/g, ' $1').trim();

    const { lower, spaceAroundNumber } = opts;

    if (spaceAroundNumber) {
        s = addSpaceAroundNumber(s);
    }

	return lower ? s.toLowerCase() : s;
}

export const addSpaceAroundNumber = (str: string): string => {
    return str.replace(/((?<=[^0-9\s])[0-9]|(?<=[0-9])[^0-9\s])/g, ' $1').trim();
}

export const spaceToDash = (value: string | number): string => {
  return `${ value }`.replaceAll(' ', '-').toLowerCase();
};

export const underscoreToSpace = (str: string, lower = false, capitalizeSubstrings = true): string => {
    const parts: string[] = str.split('_');
    let s: string = parts.join(' ');

    // if string is all upper case we can assume it looks like ex. "REFERENCE_NUMBER"
    if (str.toUpperCase() === str && capitalizeSubstrings) {
        // change ex. ['REFERENCE', 'NUMBER'] -> 'Reference Number'
        s = parts.map(part => capFirstLetter(part.toLowerCase())).join(' ');
    }

    return lower ? s.toLowerCase() : s;
}

export const formatName = (name: string): string => {
  if (!name) return "";
  let [first, second] = name.split(".");
  return `${first.substring(0, 1).toUpperCase()} ${capFirstLetter(second)}`;
}

// TODO: to be updated
export const PHONE_REGEX = /^[0-9\+]{1}[0-9\s\-]{3,15}$/;

export const NUMBER_OR_RANGE_REGEX = /^\d+(\.?\d+)?(\s*-{1}\s*\d+(\.?\d+)?)?$/;

export const NEGATIVE_NUMBER_OR_RANGE_REGEX = /^-?\d+(\.?\d+)?(\s*-{1}\s*-?\d+(\.?\d+)?)?$/;

export const TIME_REGEX = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/; // 24h format, with optional leading 0

export const checkIfNumberOrRange = (value: string, allowNegative?: boolean): boolean => {
    if (allowNegative) {
        return NEGATIVE_NUMBER_OR_RANGE_REGEX.test(value);
    }

    return NUMBER_OR_RANGE_REGEX.test(value);
}

const URL_REGEX = /((?:https?:\/\/|www\.)[A-Z0-9+\-&@#/%?=~_|!:,.;]*[A-Z0-9\-+&@#/%=~_|])/i;

export const getTextWithLinks = (text: string): JSX.Element => {
    const parts = text.split(URL_REGEX);

    return <span>{parts.map((part, index) =>
        URL_REGEX.test(part) ? <a key={`${part}_${index}`} target='_blank' rel='noopener noreferrer' href={part}>{part}</a> : part)
    }</span>;
}

export const uniqueId = (): string => {
    return crypto.randomUUID().split("-")[0];
}

export const asEncoded = (prop: string | object | [], hash: boolean = true): string => {
    if (typeof prop !== "string" ) prop = JSON.stringify(prop);

    return hash ? window.btoa(prop) : CRC32.calculate(prop).toString();
}

/**
 * Will capitalise the first letter of a given string. Trims any whitespace
 *
 * @param {string} phrase
 * @returns {string}
 */
export const capFirstLetter = (phrase: string): string => {
	if (!phrase) return "";
	const [first, ...rest] = phrase.trim();
	return [first.toUpperCase(), ...rest].join("");
}

export const sanitiseSystemString = (phrase: string): string => {
    if (!notNil(phrase)) return "";
    return phrase.replace(/_|-/g, " ").split(" ").map(i => capFirstLetter(i)).join(" ")
}


export const stripUUID = (path:string): string => path.replace(/\/?[\da-zA-Z]{8}-([\da-zA-Z]{4}-){3}[\da-zA-Z]{12}/g, "")


/**
 * Strips a passed URL string of a given parameter content. Ensures that
 * returned path will always have a trailing fwd-slash
 *
 * @param {string} path
 * @param {(string | undefined)} param
 * @returns {string}
 */
export const stripParams = (path: string, param?: string | null): string => {
	const pattern = new RegExp(`(/{0,1})${param}`, 'gi');

    // remove trailing slash at the end of a path if persist
    // because it will be added further
    path = path.replace(/\/*$/gi, '');

	return `${param ? path.replace(pattern, '') : path}/`;
}

export const addUnit = (input: number | string, unit?: string, keepZero = false): number | string =>
    (input === 0 || input === '0') && !keepZero ? '-' : `${ input }${ unit }`;

type EmailCheck = {
	passed: string[],
	failed: string[],
}


/**
 * Utility method which takes a passed string, extracting collections of both correctly/valid email addresses and those which
 * are badly structured. Itmes within the string can be deliminated by any comma, semi-colon and linebreak characters - or a mix thereof.
 *
 * Can also accomodates strings which contain recipient lists copy/pasted from M$ Outlook; so are formatted in a name <address> construct
 * @param raw - string containing one or more email addresses which need validating.
 * @returns `EmailCheck` Object containing collections of `passed` (valid) and `failed` (invalid) email addresses
 *
*/
export const stringToEmailAddresses = (raw: string): EmailCheck => {


	const addresses: EmailCheck = raw.split(/\r|\n|\;|\,/).filter(f => f).reduce(
		(a: EmailCheck, c: string): EmailCheck => {
			const result = c.trim().match(/[\w\+.-]+@[\w.-]+\.[a-zA-Z]{2,4}(?=$|>)/gi);

			const [ match ] = result ?? []

			const passed = result ? [...a.passed, match as string] : a.passed ?? [];
			const failed = result ? a.failed : [...a.failed, c];

			return { passed, failed };
		},
		{ passed: [], failed: [] } as EmailCheck
	)

	return addresses;
}

export const isLetterFirst = (value: string | number | null | undefined):boolean => {
  return notNil(value) && !!`${ value }`.match(/^[a-z]/i);
};

export {}