import { forwardRef,
	useImperativeHandle,
	ChangeEvent,
	useCallback,
	useState,
	useEffect,
	FocusEvent,
	ReactNode,
	useMemo
} from 'react';
import { Button } from 'primereact/button';
import { clsx } from 'clsx';
import { DateTime } from 'luxon';

import { InputText } from 'primereact/inputtext';

import { ConvertStringApi, ParssedDateTimeResult } from './Services/ConvertString';

interface DateTimeRangeProps {
	defaultValue: string | (() => string) | undefined;
	onDateParseError?: (value: string) => void;
	onEmptyValue?: (value: string) => void;
	onFocus?: () => void;
	onBlur?: () => void;
	required?: boolean;
	showErrorMessage?: boolean;
	className?: string;
	label?: string | undefined;
	onParsingStart?: () => void;
	validateAfterTouch?: boolean;
	onDateParsed?: (arg: ParssedDateTimeResult) => void;
	placeholder?: string;
	retainOriginal?: boolean;
	showNowButton?: boolean;
	disabled?: boolean;
}

export type ExternalHandles = {
	reset: () => void;
}

const DateTimeRange = forwardRef<ExternalHandles, DateTimeRangeProps>((props: DateTimeRangeProps, ref): JSX.Element => {

	const {
		defaultValue,
		label,
		onDateParsed,
		onEmptyValue,
		onFocus,
		onBlur,
		placeholder,
		className,
		showErrorMessage = true,
		onParsingStart,
		validateAfterTouch = false,
		onDateParseError = onEmptyValue,
		required = true,
		retainOriginal = false,
		showNowButton = false,
		disabled
	} = props;

	const [ value, setValue ] = useState(defaultValue);
	const [ error, setError ] = useState<string | null>(null);
	const [ touched, setTouched ] = useState<boolean>(false);
	const [ isParsing, setIsParsing ] = useState<boolean>(false);

	useImperativeHandle(ref, () => ({
		reset() {
			setValue(defaultValue);
			setError(null);
			setTouched(false);
		}
	}))

	const handleUpdate = useCallback(async () => {
		if (value && onDateParsed) {
			setIsParsing(true);
			onParsingStart && onParsingStart();
			ConvertStringApi.getParsedDate(value)
				.then(data => {
					if (error) {
						setError(null);
					}
					setIsParsing(false);
					onDateParsed && onDateParsed(data?.results);

					if (retainOriginal) setValue(data.results.original);
				})
				.catch((error) => {
					setIsParsing(false);
					setError(error.response?.data?.error?.errorObject ?? 'Error');
					onDateParseError && onDateParseError(value);
				});
		} else if (`${ value }`.trim() === '') {
			if (!required) {
				setError(null);
			}
			onEmptyValue && onEmptyValue('');
		}
	}, [value, onDateParsed, onParsingStart, error, retainOriginal, onDateParseError, required, onEmptyValue]);

  useEffect(() => {
		// Do not update input with default value when there is an error or it's parsing
		if (error === null && !isParsing && !retainOriginal) {
			setValue(defaultValue ?? '');
		}

		if (`${ defaultValue }`.trim() === '' && required) {
			(validateAfterTouch && touched) || (!validateAfterTouch && showErrorMessage) ? setError('Required field') : setError(null);
		}
  }, [defaultValue, error, isParsing, required, retainOriginal, showErrorMessage, touched, validateAfterTouch]);

	const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setValue(e.target.value)
	}

	const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
		e.target.select();
		onFocus && onFocus();
	}

	const handleNowButtonClick = (): void => {
		setError(null);

		if (typeof onDateParsed === 'function') {
			const now = DateTime.utc().toString();
			onDateParsed(ConvertStringApi.parseDateTime({ from: now, original: now, to: now }));
		}
	}

	const Wrapper = useMemo(() => ({ children }: { children: ReactNode; }) =>
		showNowButton ?
			<span className='p-inputgroup'>
				{children}
				<Button type='button' size='small' text className='p-inputgroup-addon' onClick={handleNowButtonClick}>Now</Button>
			</span> :
			<>{children}</>,
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showNowButton]
	);

	return (
		<div className="form-input__container">
			{ label &&
				<label htmlFor="datetimerange-input">{label}</label>
			}
			<Wrapper>
				<InputText
					id="datetimerange-input"
					name="datetimerange-input"
					pt={{
						root: {
							autoCapitalize: 'off',
							// @ts-ignore
							autoComplete: 'off'
						}
					}}
					value={value}
					placeholder={placeholder}
					disabled={disabled}
					onChange={handleChange}
					onBlur={() => {
						handleUpdate();
						setTouched(true);
						onBlur && onBlur();
					}}
					onFocus={handleFocus}
					onKeyUp={(e) => {
						if (e.key === 'Enter') { (e.target as HTMLInputElement).blur(); }
					}
					}
					className={clsx(className, {
						"p-invalid": (required && error !== null && (showErrorMessage || touched)) || (!required && error !== null),
					})}
				/>
			</Wrapper>
			{showErrorMessage && error &&
				<small className='message-invalid'>{error || 'Invalid input'}</small>
			}
		</div>
	);
});

DateTimeRange.displayName = 'DateTimeRange';

export default DateTimeRange;